import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      site {
        siteMetadata {
          title
          pageTitles {
            contact
          }
          contact {
            email
            mobile
            landLine
            location {
              street
              street2
              city
              state
              zip
            }
          }
        }
      }
      contact01: file(relativePath: { eq: "contact-hero-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const { title: siteTitle, pageTitles, contact } = data.site.siteMetadata
  const { contact01 } = data
  const { email, mobile, landLine, location } = contact
  const { street, street2, city, state, zip } = location

  return (
    <Layout>
      <Seo title={`${siteTitle} | ${pageTitles.about}`} />
      <BackgroundImage
        Tag="section"
        {...convertToBgImage(getImage(contact01))}
        className="hero contact01"
        role="parallax"
        preserveStackingContext
      >
        <div className="wrapper">
          <h1>{pageTitles.contact}</h1>
        </div>
      </BackgroundImage>
      <section>
        <div className="wrapper">
          <dl>
            <dt>Email</dt>
            <dd>
              <a href={`mailto:${email}`}>{email}</a>
            </dd>
            <dt>Call or Text</dt>
            <dd>
              <a href={`sms:+1${mobile.replace(/\D/g, "")}`}>{mobile}</a>
            </dd>
            <dt>Land Line</dt>
            <dd>
              <a href={`tel:+1${landLine.replace(/\D/g, "")}`}>{landLine}</a>
            </dd>
            <dt>Location</dt>
            <dd>
              {street}
              <br />
              {street2}
              <br />
              {city} {state}, {zip}
            </dd>
          </dl>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
